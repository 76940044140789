<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div
        class="card-header flex-nowrap border-0 pt-6 pb-0"
        style="border:2px solid red"
      >
        <div class="card-title">
          <div class="card-label">
            <h3>
              Visualización de listado de fotos
              <span class="d-block text-muted pt-2 font-size-sm"
                >Listado de fotos de perfil de estudiantes (2023); segmentadas
                en grupos académicos y técnicos por cada grado.</span
              >
            </h3>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->
      <div class="card-body px-1 px-sm-5 py-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-2 mb-5">
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row
                    ><v-col>
                      <p class="ma-0 pa-0 text-body-1">
                        <strong>
                          Debe seleccionar un grado, luego dependiendo del grado
                          seleccionado se debe seleccionar el grupo académico o
                          un grupo específico de una especialidad.
                        </strong>
                      </p>
                    </v-col></v-row
                  >
                  <v-row class="align-items-center mb-5">
                    <!-- begin::select academic level filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:grade combo -->
                        <v-select
                          label="Grado"
                          outlined
                          :loading="areGradesLoading"
                          :disabled="areGradesLoading"
                          hide-details
                          :items="grades"
                          item-text="name"
                          item-value="id"
                          v-model="report.params.grade_id"
                          @click="resetReportParams()"
                          @change="loadAcademicGroups(), loadTechnicalGroups()"
                        >
                          <!-- begin::selected grade item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected grade item -->
                        </v-select>
                        <!-- end:grade combo -->
                      </div>
                    </v-col>
                    <!-- end::select academic level filter-->

                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <!-- begin:: academicGroup combo -->
                      <v-select
                        v-if="academicLevelId != BACHILLERATO_ID"
                        :label="
                          areAcademicGroupsLoading
                            ? 'Cargando grupos académicos..'
                            : 'Grupo académico'
                        "
                        :loading="areAcademicGroupsLoading"
                        :disabled="
                          areAcademicGroupsLoading ||
                            !academicGroups.length ||
                            !report.params.grade_id
                        "
                        outlined
                        hide-details
                        :items="academicGroups"
                        item-text="grade_section_group_formatted"
                        item-value="id"
                        return-object
                        v-model="report.params.grade_section_group"
                        @change="loadSelectedGroup()"
                      >
                        <!-- begin::selected academicGroup item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{
                              item.grade_section_group_formatted
                            }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected academicGroup item -->
                      </v-select>
                      <!-- end:: academicGroup combo -->

                      <!-- begin:: technicalGroups combo -->
                      <v-select
                        v-else
                        :label="
                          areTechnicalGroupsLoading
                            ? 'Cargando grupos técnicos..'
                            : 'Grupo técnico'
                        "
                        :loading="areTechnicalGroupsLoading"
                        :disabled="
                          areTechnicalGroupsLoading ||
                            !technicalGroups.length ||
                            !report.params.grade_id
                        "
                        outlined
                        hide-details
                        :items="technicalGroups"
                        item-text="grade_speciality_group_formatted"
                        item-value="id"
                        return-object
                        v-model="report.params.grade_speciality_group"
                        @change="loadSelectedGroup()"
                      >
                        <!-- begin::selected technicalGroup item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{
                              item.grade_speciality_group_formatted
                            }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected technicalGroup item -->
                      </v-select>
                      <!-- end:: technicalGroups combo -->
                    </v-col>
                    <!-- end::select section/group filter-->
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->
            </v-col>
          </v-row>

          <!-- Collapsibles that contains student pictures by grade groups -->

          <!-- begin::loading screen -->
          <div
            v-if="
              isLoadingAcademicGroupStudents || isLoadingTechnicalGroupStudents
            "
          >
            <v-sheet class="mx-auto mt-5 py-10 px-4" elevation="0">
              <p class="text-center font-weight-bold text-h6">
                Cargando estudiantes del grupo seleccionado
              </p>
              <v-row class="mt-5">
                <v-col class="mx-auto" cols="12" md="6" lg="4" xl="3">
                  <v-progress-linear
                    indeterminate
                    color="blue darken-2"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
          <!-- end::loading screen -->

          <v-row>
            <!-- begin::academic group expandable -->
            <v-col
              v-if="
                canShowStudents &&
                  academicLevelId != BACHILLERATO_ID &&
                  !isLoadingAcademicGroupStudents
              "
              cols="12"
            >
              <v-expansion-panels
                class="rounded-lg mb-5"
                v-if="gradeSectionGroups.length > 0"
              >
                <v-expansion-panel
                  v-for="(item, i) in gradeSectionGroups"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    <span class="font-weight-bold text-h6"
                      >{{ grade_tercerCiclo(item) }} -
                      <v-chip
                        :color="sectionGroupColors[`${sectionGroup}`]"
                        outlined
                        small
                      >
                        <v-icon left>
                          mdi-account-supervisor-circle
                        </v-icon>
                        <p class="mb-0 font-weight-medium">
                          Sección {{ sectionGroup }}
                        </p>
                      </v-chip>
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col
                        v-for="(student, index) in item.students"
                        :key="index"
                        class="d-flex child-flex"
                        cols="12"
                        sm="4"
                        lg="3"
                        xl="2"
                      >
                        <v-card class="elevation-0" outlined>
                          <v-img
                            :src="student.photo"
                            :lazy-src="student.photo"
                            aspect-ratio="0.80"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <v-card-title class="flex-column">
                            <p
                              class="mb-1 text-body-2 text-center font-weight-bold"
                            >
                              {{ student.code }}
                            </p>
                            <p
                              class="mb-0 font-weight-medium text-body-1 text-center"
                            >
                              {{ student.first_name }}
                            </p>
                            <p
                              class="font-weight-medium text-body-1 text-center"
                            >
                              {{ student.last_name }}
                            </p>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- begin::fallback if academic group is empty -->
              <div v-else>
                <v-row>
                  <v-col
                    class="mx-auto d-flex flex-column"
                    cols="12"
                    sm="8"
                    md="6"
                    lg="4"
                  >
                    <div class="mx-auto mb-4 pa-8 rounded-circle red lighten-4">
                      <v-icon large color="red">mdi-account-off-outline</v-icon>
                    </div>
                    <div>
                      <p class="text-center font-weight-bold text-h6 mb-1">
                        Este grupo está vacío
                      </p>
                      <p class="text-center font-weight-medium text-body-1">
                        No hay estudiantes asignados a esta sección académica.
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <!-- end::fallback if academic group is empty -->
            </v-col>
            <!-- end::academic group expandable -->

            <!-- begin::speciality group expandable -->
            <v-col
              v-if="
                canShowStudents &&
                  academicLevelId == BACHILLERATO_ID &&
                  !isLoadingTechnicalGroupStudents
              "
            >
              <v-expansion-panels
                class="rounded-lg mb-5"
                v-if="gradeSpecialityGroups.length > 0"
              >
                <v-expansion-panel
                  v-for="(item, index) in gradeSpecialityGroups"
                  :key="index"
                >
                  <v-expansion-panel-header>
                    <span class="font-weight-bold text-h6"
                      >{{ grade_bachillerato(item) }} -
                      <span class="font-weight-medium">{{
                        specialityGroup
                      }}</span>
                      -
                      <v-chip
                        :color="
                          sectionGroupColors[`${specialitySectionGroup(item)}`]
                        "
                        outlined
                        small
                      >
                        <v-icon left>
                          mdi-account-supervisor-circle
                        </v-icon>
                        <p class="mb-0 font-weight-medium">
                          Sección {{ specialitySectionGroup(item) }}
                        </p>
                      </v-chip>
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col
                        v-for="(student, i) in item.students"
                        :key="i"
                        class="d-flex child-flex"
                        cols="12"
                        sm="4"
                        lg="3"
                        xl="2"
                      >
                        <v-card class="elevation-0" outlined>
                          <v-img
                            :src="student.photo"
                            :lazy-src="student.photo"
                            aspect-ratio="0.80"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <v-card-title class="flex-column">
                            <p
                              class="mb-1 text-body-2 text-center font-weight-bold"
                            >
                              {{ student.code }}
                            </p>
                            <p
                              class="mb-0 font-weight-medium text-body-1 text-center"
                            >
                              {{ student.first_name }}
                            </p>
                            <p
                              class="font-weight-medium text-body-1 text-center"
                            >
                              {{ student.last_name }}
                            </p>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- begin::fallback if speciality group is empty -->
              <div v-else>
                <v-row>
                  <v-col
                    class="mx-auto d-flex flex-column"
                    cols="12"
                    sm="8"
                    md="6"
                    lg="4"
                  >
                    <div class="mx-auto mb-4 pa-8 rounded-circle red lighten-4">
                      <v-icon large color="red">mdi-cube-off-outline</v-icon>
                    </div>
                    <div>
                      <p class="text-center font-weight-bold text-h6 mb-1">
                        Este grupo está vacío
                      </p>
                      <p class="text-center font-weight-medium text-body-1">
                        No hay estudiantes asignados a este grupo de
                        especialidad.
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <!-- end::fallback if speciality group is empty -->
            </v-col>
            <!-- end::speciality group expandable -->
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import gradeRepository from "@/repositories/gradeRepository";
import groupRepository from "@/repositories/groupRepository";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import { mapGetters } from "vuex";

export default {
  name: "StudentsProfilePicturesByGroup",
  title: "Fotos de perfil | GE ITR",

  mounted() {
    this.loadGrades();
  },

  data() {
    return {
      grades: [],
      academicGroups: [],
      technicalGroups: [],
      gradeSectionGroups: [],
      gradeSpecialityGroups: [],
      areGradesLoading: false,
      areAcademicGroupsLoading: false,
      isLoadingAcademicGroupStudents: false,
      isLoadingTechnicalGroupStudents: false,
      areTechnicalGroupsLoading: false,
      TERCER_CICLO_ID: 1,
      BACHILLERATO_ID: 2,
      report: {
        params: {},
      },
      areStudentsLoading: false,
      sectionGroupColors: {
        "A-1": "light-blue ",
        "A-2": "light-blue darken-1",
        "A-3": "light-blue darken-2",
        "A-4": "light-blue darken-3",
        "A-5": "light-blue darken-4",
        "B-1": "teal",
        "B-2": "teal darken-1",
        "B-3": "teal darken-2",
        "B-4": "teal darken-3",
        "B-5": "teal darken-4",
        "C-1": "green darken-2",
        "D-1": "purple darken-2",
        "E-1": "indigo darken-4",
      },
    };
  },

  methods: {
    resetReportParams() {
      this.report.params = {};
      (this.gradeSpecialityGroups = []), (this.gradeSectionGroups = []);
    },

    goBack() {
      this.$router.go(-1);
    },

    // ------------------- fetching data --------------------------

    loadGrades() {
      this.areGradesLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areGradesLoading = false;
        });
    },

    loadAcademicGroups() {
      //reseting groups
      this.gradeSectionGroups = [];
      this.areAcademicGroupsLoading = true;
      academicGroupRepository
        .getAcademicGroupsByGrade(this.report.params.grade_id)
        .then(({ data }) => {
          this.academicGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areAcademicGroupsLoading = false;
        });
    },

    loadTechnicalGroups() {
      //reseting groups
      this.gradeSpecialityGroups = [];
      this.areTechnicalGroupsLoading = true;
      technicalGroupRepository
        .getTechnicalGroupsByGrade(this.report.params.grade_id)
        .then(({ data }) => {
          this.technicalGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areTechnicalGroupsLoading = false;
        });
    },

    loadAcademicGroupsStudentProfilePictures() {
      this.isLoadingAcademicGroupStudents = true;
      groupRepository
        .getStudentsProfilePicturesByAcademicGroup(
          this.report.params?.grade_section_group?.id
        )
        .then(({ data }) => {
          this.gradeSectionGroups = data;
        })
        .catch((err) => {
          console.warn(err);
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.isLoadingAcademicGroupStudents = false;
        });
    },

    loadTechnicalGroupsStudentProfilePictures() {
      this.isLoadingTechnicalGroupStudents = true;
      groupRepository
        .getStudentsProfilePicturesByTechnicalGroup(
          this.report.params?.grade_speciality_group?.id
        )
        .then(({ data }) => {
          this.gradeSpecialityGroups = data;
        })
        .catch((err) => {
          console.warn(err);
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.isLoadingTechnicalGroupStudents = false;
        });
    },

    // -------------------- Flow management and validations ----------------------

    loadSelectedGroup() {
      if (this.isEmptySelection()) {
        return;
      }

      if (this.academicLevelId == this.TERCER_CICLO_ID) {
        return this.loadAcademicGroupsStudentProfilePictures();
      }

      if (this.academicLevelId == this.BACHILLERATO_ID) {
        return this.loadTechnicalGroupsStudentProfilePictures();
      }
    },

    grade_tercerCiclo(group) {
      return group.gradeSpecialityGroup?.grades?.name;
    },

    grade_bachillerato(group) {
      return group.gradeSectionGroup?.grades?.name;
    },

    isEmptySelection() {
      return (
        !!"grade_section_group" in this.report.params ||
        !!"grade_speciality_group" in this.report.params
      );
    },

    specialitySectionGroup(group) {
      return `${group.gradeSectionGroup.section_group?.section?.name}-${group.gradeSectionGroup.section_group?.group?.name}`;
    },
  },

  computed: {
    canShowStudents() {
      return (
        this.report.params.grade_section_group != null ||
        this.report.params.grade_section_group != undefined ||
        this.report.params.grade_speciality_group != null ||
        this.report.params.grade_speciality_group != undefined
      );
    },
    sectionGroup() {
      return this.report.params.grade_section_group
        ?.grade_section_group_formatted;
    },
    specialityGroup() {
      return this.report.params.grade_speciality_group
        ?.grade_speciality_group_formatted;
    },
    academicLevelId() {
      if (!("grade_id" in this.report.params)) {
        return;
      }

      const academicLevel = this.grades.find(
        (item) => item.id == this.report.params.grade_id
      );
      return academicLevel.academic_level.id;
    },

    ...mapGetters(["currentPageActions"]),
  },
};
</script>
